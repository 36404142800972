<template>
  <div class="home">
    <van-nav-bar
      title="行情"
      placeholder
      fixed
      :border="false"
    >
    </van-nav-bar>
    <div class="domainInfo">
      <div class="main">
        <div class="logo">
          <img :src="notices.logo.url" alt="" v-if="notices.logo">
        </div>
        <div class="status">
          <div>{{timecenter}}</div>
          <div class="trade" :style="{color:opentrade == true?'#32BC77':'#F94E3C', border: opentrade == true ? '1px solid #32BC77' : '1px solid #F94E3C'}">
            {{ opentrade == true ? "开盘中" : "已停盘" }}
          </div>
        </div>
      </div>
      <van-notice-bar v-if="notices.enable && noticesText" :text="noticesText" :left-icon="require('@/assets/newImages/icon_notices_yellow_4@2x.png')" background="#FFFFFF" color="#333333" speed="60"
      />
    </div>
    <!-- 主体 -->
    <van-pull-refresh
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh"
      style="min-height: 100vh; background-color: #F6F5F9"
    >
      <div v-if="havedata">
        <!-- 现货价格 -->
        <div class="price">
            <ul>
              <van-sticky>
              <li>商品</li>
              <li>回购</li>
              <li>销售</li>
              <li>高/低</li>
              </van-sticky>
            </ul>
          <ul class="productlist" v-for="(items, index) in arrcenter.spotPrice" :key="index">
            <li class="productName">{{ items.engname }}</li>
            <li class="productPrice" :class="sendmsg ? items.productData['huigouColor'] : 'redfont'">
              <template v-if="items.productData['hgDisplay'] == 'SHOW'">
                <span v-if="items.productData['huigouColor'] === 'redfontBack'" class="triangleUp"></span>
                <span v-if="items.productData['huigouColor'] === 'greenfontBack'" class="triangleDown"></span>
                {{ items.productData["huigou"] | changeTwoDecimal_x }}
              </template>
              <template v-else> - </template>
            </li>
            <li class="productPrice" :class="items.productData['huigouColor']">
              <template v-if="items.productData['xsDisplay'] == 'SHOW'">
                <span v-if="items.productData['huigouColor'] === 'redfontBack'" class="triangleUp"></span>
                <span v-if="items.productData['huigouColor'] === 'greenfontBack'" class="triangleDown"></span>
                {{ items.productData["xiaoshou"] | changeTwoDecimal_x}}
              </template>
              <template v-else> - </template>
            </li>
            <li class="lastli">
                <div class="high">
                  {{ items.productData["high"] | changeTwoDecimal_x }}
                </div>
                <div class="low">
                  {{ items.productData["low"] | changeTwoDecimal_x }}
                </div>
              </li>
          </ul>
        </div>
        <!-- 上交所价格 -->
        <div class="price">
          <ul class="topul">
            <li>上交所报价</li>
          </ul>
          <ul class="productlist" v-for="(items, index) in arrcenter.shangjinsuo" :key="index">
            <li class="productName">{{ items.productData["name"] =='黄金99.99'?'黄金9999':items.productData["name"] =='黄金99.95'?'黄金9995': items.productData["name"]}}</li>
            <li class="productPrice" :class="sendmsg ? items.productData['huigouColor'] : 'redfont'">
              <template v-if="items.productData['hgDisplay'] == 'SHOW'">
                <span v-if="items.productData['huigouColor'] === 'redfontBack'" class="triangleUp"></span>
                <span v-if="items.productData['huigouColor'] === 'greenfontBack'" class="triangleDown"></span>
                <template
                  v-if="
                    items.productData['name'] == '白银(T+D)'
                  "
                >
                  {{ items.productData["huigou"] }}
                </template>
                <template v-else>
                  {{ items.productData["huigou"] | changeTwoDecimal_f }}
                </template>
              </template>
              <template v-else> - </template>
            </li>
            <li class="productPrice" :class="items.productData['huigouColor']">
              <template v-if="items.productData['xsDisplay'] == 'SHOW'">
                <span v-if="items.productData['huigouColor'] === 'redfontBack'" class="triangleUp"></span>
                <span v-if="items.productData['huigouColor'] === 'greenfontBack'" class="triangleDown"></span>
                <template
                  v-if="
                    items.productData['name'] == '白银(T+D)'
                  "
                >
                  {{ items.productData["xiaoshou"] }}
                </template>
                <template v-else>
                  {{ items.productData["xiaoshou"] | changeTwoDecimal_f }}
                </template>
              </template>
              <template v-else> - </template>
            </li>
            <li class="lastli">
                <div class="high">
                  <template
                    v-if="
                    items.productData['name'] == '白银(T+D)'
                  "
                  >
                    {{ items.productData["high"] }}
                  </template>
                  <template v-else>
                    {{ items.productData["high"] | changeTwoDecimal_f }}
                  </template>
                </div>
                <div class="low">
                  <template
                    v-if="
                    items.productData['name'] == '白银(T+D)'
                  "
                  >
                    {{ items.productData["low"] }}
                  </template>
                  <template v-else>
                    {{ items.productData["low"] | changeTwoDecimal_f }}
                  </template>
                </div>
              </li>
          </ul>
        </div>
        <!-- 国际价格 -->
        <div class="price">
          <ul class="topul">
            <li>国际价格</li>
          </ul>
          <ul class="productlist" v-for="(items, index) in arrcenter.guojixianhuo" :key="index">
            <li class="productName">{{ items.productData["name"] }}</li>
            <li class="productPrice" :class="sendmsg ? items.productData['huigouColor'] : 'redfont'">
              <template v-if="items.productData['hgDisplay'] == 'SHOW'">
                <span v-if="items.productData['huigouColor'] === 'redfontBack'" class="triangleUp"></span>
                <span v-if="items.productData['huigouColor'] === 'greenfontBack'" class="triangleDown"></span>
                <template
                  v-if="
                    items.productData['name'] == '伦敦金' ||
                      items.productData['name'] == '伦敦钯' ||
                      items.productData['name'] == '伦敦铂'
                  "
                >
                  {{ items.productData["huigou"] | changeTwoDecimal_f }}
                </template>
                <template
                  v-else-if="
                    items.productData['name'] == '伦敦银' ||
                      items.productData['name'] == '美元指数'
                  "
                >
                  {{ items.productData["huigou"] | changeTwoDecimal_fthree }}
                </template>
                <template v-else>
                  {{ items.productData["huigou"] }}
                </template>
              </template>
              <template v-else> - </template>
            </li>
            <li class="productPrice" :class="sendmsg ? items.productData['huigouColor'] : 'redfont'">
              <template v-if="items.productData['xsDisplay'] == 'SHOW'">
                <span v-if="items.productData['huigouColor'] === 'redfontBack'" class="triangleUp"></span>
                <span v-if="items.productData['huigouColor'] === 'greenfontBack'" class="triangleDown"></span>
                <template
                  v-if="
                    items.productData['name'] == '伦敦金' ||
                      items.productData['name'] == '伦敦钯' ||
                      items.productData['name'] == '伦敦铂'
                  "
                >
                  {{ items.productData["xiaoshou"] | changeTwoDecimal_f }}
                </template>
                <template
                  v-else-if="
                    items.productData['name'] == '伦敦银' ||
                      items.productData['name'] == '美元指数'
                  "
                >
                  {{ items.productData["xiaoshou"] | changeTwoDecimal_fthree }}
                </template>
                <template v-else>
                  {{ items.productData["xiaoshou"] }}
                </template>
              </template>
              <template v-else> - </template>
            </li>
            <li class="lastli">
                <div class="high">
                  <template
                    v-if="
                    items.productData['name'] == '伦敦金' ||
                      items.productData['name'] == '伦敦钯' ||
                      items.productData['name'] == '伦敦铂'
                  "
                  >
                    {{ items.productData["high"] | changeTwoDecimal_f }}
                  </template>
                  <template
                    v-else-if="
                    items.productData['name'] == '伦敦银' ||
                      items.productData['name'] == '美元指数'
                  "
                  >
                    {{ items.productData["high"] | changeTwoDecimal_fthree }}
                  </template>
                  <template v-else>
                    {{ items.productData["high"] }}
                  </template>
                </div>
                <div class="low">
                  <template
                    v-if="
                    items.productData['name'] == '伦敦金' ||
                      items.productData['name'] == '伦敦钯' ||
                      items.productData['name'] == '伦敦铂'
                  "
                  >
                    {{ items.productData["low"] | changeTwoDecimal_f }}
                  </template>
                  <template
                    v-else-if="
                    items.productData['name'] == '伦敦银' ||
                      items.productData['name'] == '美元指数'
                  "
                  >
                    {{ items.productData["low"] | changeTwoDecimal_fthree }}
                  </template>
                  <template v-else>
                    {{ items.productData["low"] }}
                  </template>
                </div>
              </li>
          </ul>
        </div>
        <!-- 备注信息 -->
        <div class="remark">
          <div class="describe" v-if="notices.tips">温馨提示：<span>{{notices.tips}}</span></div>
          <div class="describe" v-if="notices.address">地址：<span>{{notices.address}}</span></div>
          <div class="describe" v-if="notices.phone">业务咨询电话：<span>{{notices.phone}}</span></div>
        </div>
        <!-- 域名备案 -->
        <div class="beian">
          <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2023131551号-1</a>
        </div>
      </div>
    </van-pull-refresh>
    <!-- 弹出通知 -->
    <van-popup v-model="showNotice" @close="noticeClose" close-icon="close" style="background: transparent; width: 350px">
      <img :src="noticeImg" alt="">
      <div style="text-align:center">
        <img src="@/assets/images/close_notice.png" alt="" style="height: 40px; width: 40px" @click="noticeClose">
      </div>
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import Stomp from "stompjs";
import NoticeBar from '@/components/Noticebar'
import router from "../router/index";
import quoted from "./quoted";
import * as api from "@/utils/api";
import axios from "axios";
import * as PriceLineChart from "@/utils/api";
// import { getStyle } from 'node_modules/highcharts/highcharts';
export default {
  name: "Home",
  data() {
    return {
      // 下拉刷新
      showNotice: false,
      noticeImg: null,
      isLoading:false,
      quotationShow: false,
      quotationForm: {
        key: "huangjin9999",
        type: "HUIGOU",
        price: "",
        nowPrice: "",
        operator: "EQ",
        alertType: ["WEIXIN_GONGZHONGHAO"],
        openId: "",
        scene: 1000
      },
      category: [{name:'黄金',id:'0', key: 'huangjin9999'},{name:'铂金',id:'1', key: 'bojin9996'}],
      categoryShow: false,
      quotationAction: "",
      userinfo: "",
      year: "",
      mouth: Number,
      day: Number,
      sendmsg: false,
      onedata: [],
      // 旧值判断
      isfirstval: false,
      // 克隆后获取之前的值做比较
      lastarr: [],
      // 深度克隆后的赋值
      updataarr: [],
      firstload: true,
      wsPrice: null,
      prePrice: {},
      isload: true,
      arrcenter: {
        huangjin: [],
        bojin: [],
        bajin: [],
        baiyin: [],
        shangjinsuo: [],
        guojixianhuo: [],
        // 现货价格
        spotPrice:[]
      },
      newarrlist: [],
      // 是否开盘中
      opentrade: false,
      havedata: false,
      timecenter: "",
      client: {},
      productall: {
        huangjin: [],
        bojin: [],
        bajin: [],
        baiyin: [],
        shangjinsuo: [],
        guojixianhuo: []
      },
      new_tuisong_val_last: {},
      new_tuisong_val_before: {},

      Compareval: {
        huangjin: [],
        bojin: [],
        bajin: [],
        baiyin: [],
        shangjinsuo: [],
        guojixianhuo: []
      },
      appId: "wxf3ab293ae093d4ca",
      templateId: 'TmbNMQDfZVc57W4kZWu98WjGMl5NnptSy6NOW5AftGc',
      notices: {
        enable: false,
        notices: [],
        logo: null,
      },
      noticesText: "",
    };
  },
  activated() {
    this.userinfo = JSON.parse( localStorage.getItem('userinfo'))
  },
  mounted() {
    console.log(this.$route.query)
    if(this.$route.query.referrerCode) {
      localStorage.setItem('referrerCode', this.$route.query.referrerCode)
    }
    if(this.$route.query.code){
      // 微信获取openIdAPI
      api.getWeixinOpenId(this.$route.query.code).then(res => {
        console.log(res)
        if (!res.openId) {
          this.$toast.fail("微信登录失败");
        } else {
          localStorage.setItem('openId', res.openId)
          this.quotationShowHandle()
        }
      })
    }
    if (this.$route.query.action === 'confirm'){
      // 客户同意
      this.quotationAction = this.$route.query.action
      this.quotationShowHandle()
    } else if (this.$route.query.action === 'cancel') {
      this.quotationAction = this.$route.query.action
      this.$toast.fail("到价提醒功能需确认接收公众号消息推送");
    }
    const that = this;
    this.connect();
    api.getAboutPhoto().then(res => {
      res.forEach(item => {
        if (item.eventType === "SPLASH") {
          that.noticeImg = item.img;
        }
      });
      const noticeParams = JSON.parse(localStorage.getItem("noticeParam1"));
      if (!that.noticeImg) {
        that.showNotice = false;
      } else if (!noticeParams) {
        that.showNotice = true;
      } else {
        const noticeTime = noticeParams.noticeTime;
        const noticeImg = noticeParams.noticeImg;
        console.log("noticeParams: ", noticeParams);
        const nowTime = new Date().getTime();
        const differenceTime = Math.floor(
          (nowTime - noticeTime) / (24 * 3600 * 1000)
        );
        that.showNotice = that.noticeImg !== noticeImg || differenceTime > 1;
      }
    });
    api.getSettingNotices().then(res=>{
      this.notices = res
      this.notices.notices.forEach(item => {
        this.noticesText += item.content + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'
      })
    })
    setInterval(() => {
      const t = that.onedata["time"];
      const s = new Date().getTime()
      let date = new Date(Number(t) * 1000);
      if (isNaN(t)) {
        date = new Date();
      }

      this.timebox(date);
    },500);
  },
  computed: {},
  watch: {
    onedata: function(newN, oldN) {
      const than = this;

      // console.log(newN);
      Object.entries(newN).forEach(([typeKey, type]) => {
        if (["time", "trade", "id"].includes(typeKey)) {
          return;
        }
        Object.entries(type.prices).forEach(([goodsKey, goods]) => {
          //  debugger
          let huigouColor = "redfont";
          if (oldN && !Array.isArray(oldN)) {
            const oldGoods = oldN[typeKey].prices[goodsKey];
            const oldHuigou = oldGoods["huigou"];
            const newHuigou = goods["huigou"];
            const oldHuigouColor = oldGoods["huigouColor"];
            // console.log(newHuigou, oldHuigou);
            // console.log(oldHuigouColor);
            // debugger
            huigouColor =
              newHuigou < oldHuigou
                ? "greenfont"
                : newHuigou == oldHuigou
                  ? oldHuigouColor
                  : "redfont";
          }
          goods["huigouColor"] = huigouColor;
          // debugger
          // console.log(huigouColor);
        });
      });
      if (oldN instanceof Array) {
      } else {
        this.new_tuisong_val_before = JSON.parse(JSON.stringify(oldN));
        this.new_tuisong_val_last = JSON.parse(JSON.stringify(newN));
        this.sendmsg = true;
        this.newlistsort(this.new_tuisong_val_last);
      }
    }
  },
  filters: {
    changeTwoDecimal_f(x) {
      var f_x = parseFloat(x);
      if (isNaN(f_x)) {
        return 0;
      }
      // Math.round
      var f_x = (x * 100) / 100;
      let s_x = f_x.toString();
      let pos_decimal = s_x.indexOf(".");
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
      }
      while (s_x.length <= pos_decimal + 2) {
        s_x += "0";
      }
      return s_x;

    },
    changeTwoDecimal_x(x) {
      var f_x = parseFloat(x);
      if (isNaN(f_x)) {
        return 0;
      }
      // Math.round
      var f_x = (x * 100) / 100;
      let s_x = f_x.toString();
      let pos_decimal = s_x.indexOf(".");
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
      }
      while (s_x.length <= pos_decimal + 2) {
        s_x += "0";
      }

      if (s_x.length >= 7) {
        // console.log('sx',s_x,'x',Number(s_x).toFixed(3));

        return Number(s_x).toFixed(3);
      }

      return s_x;

    },
    changeTwoDecimal_fthree(x) {
      var f_x = parseFloat(x);
      if (isNaN(f_x)) {
        return 0;
      }
      // Math.round
      var f_x = (x * 100) / 100;
      let s_x = f_x.toString();
      let pos_decimal = s_x.indexOf(".");
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
      }
      while (s_x.length <= pos_decimal + 3) {
        s_x += "0";
      }
      if (s_x.length >= 7) {
        return Number(s_x).toFixed(3);
      }
      return s_x;
    }
  },
  methods: {
    onRefresh(){

      this.connect()
      this.isLoading = false;
    },
    banklist(){
      this.$router.push('/bank_list')
    },
    // Buygold(){
    //   this.$router.push('/Buy_gold')
    // },
    quoted(){
      this.$router.push('/quoted')
    },
    newlistsort(data) {
      const than = this;
      than.arrcenter.huangjin = [];
      than.arrcenter.bojin = [];
      than.arrcenter.bajin = [];
      than.arrcenter.baiyin = [];
      than.arrcenter.guojixianhuo = [];
      than.arrcenter.shangjinsuo = [];
      than.arrcenter.spotPrice =[]
      than.productall.huangjin = [];
      than.productall.bojin = [];
      than.productall.bajin = [];
      than.productall.baiyin = [];
      than.productall.shangjinsuo = [];
      const resbody = data;
      for (const i in resbody) {
        if (resbody[i].name === "黄金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.huangjin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });

            }
          }

          this.arrcenter.huangjin.sort(this.compare("sort"));
          // this.productall.huangjin = resbody[i].prices
        }
        if (resbody[i].name === "铂金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.bojin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.bojin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "钯金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.bajin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.bajin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "白银") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.baiyin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b],
                time: resbody.time
              });
            }
          }
          this.arrcenter.baiyin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "上金所") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.shangjinsuo.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }

          this.arrcenter.shangjinsuo.sort(this.compare("sort"));
        }
        if (resbody[i].name === "国际现货") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.guojixianhuo.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.guojixianhuo.sort(this.compare("sort"));
        }
      }


      let res = 0
      this.arrcenter.huangjin.forEach((item,i)=>{
        if (item.engname  == "huangjin9999") {
          res = item.productData.huigou
          this.arrcenter.spotPrice.push({
            engname: '黄金',
            productData: item.productData
          })
        }
      })
      let res1 = 0
      this.arrcenter.baiyin.forEach((item,i)=>{
        if (item.engname  == "baiyin9999") {
          res1 = item.productData.huigou
          this.arrcenter.spotPrice.push({
            engname: '白银',
            productData: item.productData
          })
        }
      })
      let res2 = 0
      this.arrcenter.bojin.forEach((item,i)=>{
        if (item.engname == "bojin9996") {
          res2 = item.productData.huigou
          this.arrcenter.spotPrice.push({
            engname: '铂金',
            productData: item.productData
          })
        }
      })
      let res3 = 0
      this.arrcenter.bajin.forEach((item,i)=>{
        if (item.engname == "bajin9996") {
          res3 = item.productData.huigou

          this.arrcenter.spotPrice.push({
            engname: '钯金',
            productData: item.productData
          })
        }
      })
      this.getNowPrice();
    },
    // 排序
    compare(property) {
      return function(a, b) {
        const value1 = a[property];
        const value2 = b[property];
        return value1 - value2;
      };
    },
    sortNumber(a) {
      return a;
    },
    timebox(date) {
      // const year = new Date().getFullYear();
      // const month = new Date().getMonth() + 1;
      // const day = new Date().getDate();
      // const hour = new Date().getHours();
      // const Min = new Date().getMinutes();
      // const getSeconds = new Date().getSeconds();

      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const Min = date.getMinutes();
      const getSeconds = date.getSeconds();
      this.timecenter =
        year +
        "-" +
        (month <= 9 ? "0" + month : month) +
        "-" +
        (day <= 9 ? "0" + day : day) +
        " " +
        (hour <= 9 ? "0" + hour : hour) +
        ":" +
        (Min <= 9 ? "0" + Min : Min) +
        ":" +
        (getSeconds <= 9 ? "0" + getSeconds : getSeconds);

      if (hour == "16" && Min == "20" && getSeconds == "40") {
        console.log(hour + "时" + Min + "分" + getSeconds + "秒");
        this.$eventBus.$emit("onclickLists");
      }
    },
    unzip(b64Data) {
      let strData = atob(b64Data);
      const charData = strData.split("").map(function(x) {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      const data = pako.inflate(binData);
      strData = String.fromCharCode.apply(null, new Uint16Array(data));
      return decodeURIComponent(escape(strData));
    },
    onConnected() {
      // console.log(this.client)
      const topic = "/price/all";
      // let topic = '/location'
      this.client.subscribe(topic, this.responseCallback, this.onFailed);
    },
    connect() {
      console.log("process.env",process.env)
      this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      // ---初始化mqtt客户端，并连接mqtt服务
      const headers = {
        login: "username",
        passcode: "password"
      };
      this.client.connect(headers, this.onConnected, this.onFailed);
      this.client.debug = null;
    },
    onFailed(frame) {
      console.log("错误: " + frame);
      this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      if (!this.client.connected) {
        this.opentrade = false;
        setTimeout(() => {
          this.connect();
        }, 1000);
      }
    },
    deepObjectMerge(FirstOBJ_, SecondOBJ) {
      // console.log("函数内的数据")
      const FirstOBJ = FirstOBJ_ || this.wsPrice;
      for (const key in SecondOBJ) {
        FirstOBJ[key] =
          FirstOBJ[key] && FirstOBJ[key].toString() === "[object Object]"
            ? this.deepObjectMerge(FirstOBJ[key], SecondOBJ[key])
            : (FirstOBJ[key] = SecondOBJ[key]);
      }
      return FirstOBJ;
    },
    deepMerge(...objs) {
      const than = this;
      const result = Object.create(null);
      objs.forEach(obj => {

        if (obj) {
          Object.keys(obj).forEach(key => {
            const val = obj[key];
            if (than.isPlainObject(val)) {
              // 递归
              if (than.isPlainObject(result[key])) {
                result[key] = than.deepMerge(result[key], val);
              } else {
                result[key] = than.deepMerge(val);
              }
            } else {
              //  数组也要重新赋值  不然依然会引用到其他的
              if (Array.isArray(val)) {
                result[key] = [...val];
              } else {
                result[key] = val;
              }
            }
          });
        }
      });
      return result;
    },
    isPlainObject(val) {
      const toString = Object.prototype.toString;
      return toString.call(val) === "[object Object]";
    },
    extend() {
      const than = this;
      const extended = {};
      let deep = false;
      let i = 0;
      // 判断是否为深拷贝
      if (Object.prototype.toString.call(arguments[0]) === "[object Boolean]") {
        deep = arguments[0];
        i++; //如果为深拷贝则初始的i为1或者为0
      }

      // 将对象属性合并到已存在的对象中
      const merge = function(obj) {
        for (const prop in obj) {
          if (obj.hasOwnProperty(prop)) {
            // 如果属性为对象并且需要深拷贝时则使用函数递归、反之则将当前的属性替换现有的属性
            if (
              deep &&
              Object.prototype.toString.call(obj[prop]) === "[object Object]"
            ) {
              extended[prop] = than.extend(extended[prop], obj[prop]);
            } else {
              extended[prop] = obj[prop];
            }
          }
        }
      };
      // 遍历所有对象属性
      for (; i < arguments.length; i++) {
        merge(arguments[i]);
      }
      return extended;
    },
    responseCallback(frame) {
      const than = this;
      // than.onedata = []
      // console.log(eval("[" + this.unzip(frame.body) + "]"));
      const res_data = eval("[" + this.unzip(frame.body) + "]")[0];
      const parse_res = JSON.parse(JSON.stringify(res_data));
      localStorage.setItem('priceID',parse_res.time)
      if (!this.firstload) {
        than.onedata = than.deepMerge(than.wsPrice, parse_res);
        // --------------------
        than.wsPrice = than.onedata;
        // --------------------
      } else {
        than.firstload = false;
        than.updataarr = parse_res;
        than.wsPrice = parse_res;
      }
      console.debug(parse_res);
      //  是否开盘中
      this.opentrade = parse_res.trade;
      // 保存本地
      localStorage.setItem('opentrade',parse_res.trade)
      if (this.isload) {
        let resbody = "";
        if (JSON.stringify(than.new_tuisong_val_last) == "{}") {
          resbody = than.updataarr;
          this.newlistsort(resbody);
        } else {
        }
      }
      this.isload = true;
      this.havedata = true;
    },
    callPhone() {
      window.location.href = "tel:// 4001-886-722";
    },
    addRouterMap() {
      this.$router.push("/aboutUs");
    },
    noticeClose() {
      this.showNotice = false
      const noticeParams = {
        noticeTime: new Date().getTime(),
        noticeImg: this.noticeImg
      };
      localStorage.setItem("noticeParam1", JSON.stringify(noticeParams));
    },
    quotationShowHandle(){
      if(!this.isWeiXin()){
        return this.$toast('请在公众号中使用此功能'),!1
      }
      this.quotationShow = true;
      const openId = localStorage.getItem("openId");
      if (!openId) {
        const url = encodeURIComponent(process.env.VUE_APP_WEBSITE)
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      } else if (!this.quotationAction || this.quotationAction === 'cancel') {
        const url = encodeURIComponent(process.env.VUE_APP_WEBSITE)
        window.location.href = `https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=${this.appId}&scene=${Math.round(Math.random()*10000)}&template_id=${this.templateId}&redirect_url=${url}#wechat_redirect`
        // window.location.href = 'https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=wxec9e626df3d166fb&scene=1000&template_id=qDFLozFKoZoVlTUOLBlou1LeQq3aRLAZHUqfpSGr4zk&redirect_url=http%3a%2f%2ftest.shenzhenyht.com#wechat_redirect'
        // window.open(`https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=${this.appId}&scene=${Math.round(Math.random()*10000)}&template_id=${this.templateId}&redirect_url=${url}#wechat_redirect`,"_self");
      } else {
        this.quotationForm.openId = openId;
        this.quotationForm.scene = this.$route.query.scene || 1000;
      }
    },
    quotationCommit(){
      api.alertPrice(this.quotationForm).then(res=>{
        console.log(res)
        this.$toast('订阅成功!')
        this.quotationShow = false
        this.quotationAction = ""
        this.categoryShow = false
      })
    },
    quotationFormTypeChange(name){
      this.getNowPrice();
      // this.quotationForm.operator = this.quotationForm.type === 'HUIGOU' ? 'GE' : 'LE'
    },
    isWeiXin () {
      var ua = window.navigator.userAgent.toLowerCase();
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      if (ua.indexOf('micromessenger') > -1) {
        return true; // 是微信端
      } else {
        return false;
      }
    },
    getNowPrice() {
      if (this.quotationForm.key === "bojin9996") {
        this.quotationForm.nowPrice =
          this.quotationForm.type === "HUIGOU"
            ? this.arrcenter.spotPrice[2].productData["huigou"]
            : this.arrcenter.spotPrice[2].productData["xiaoshou"];
      } else if (this.quotationForm.key === "bajin9996") {
        this.quotationForm.nowPrice =
          this.quotationForm.type === "HUIGOU"
            ? this.arrcenter.spotPrice[3].productData["huigou"]
            : this.arrcenter.spotPrice[3].productData["xiaoshou"];
      } else if (this.quotationForm.key === "bojin9999") {
        this.quotationForm.nowPrice =
          this.quotationForm.type === "HUIGOU"
            ? this.arrcenter.spotPrice[1].productData["huigou"]
            : this.arrcenter.spotPrice[1].productData["xiaoshou"];
      } else {
        this.quotationForm.nowPrice =
          this.quotationForm.type === "HUIGOU"
            ? this.arrcenter.spotPrice[0].productData["huigou"]
            : this.arrcenter.spotPrice[0].productData["xiaoshou"];
      }
    },
    confirmCategory(item){
      this.categoryShow = false;
      this.quotationForm.key = item.key;
      this.quotationShow = true;
      this.getNowPrice();
    },
  },
  destroyed() {
    clearTimeout(this.setInterval);
    this.client.disconnect(function() {
      // 断开连接
      // console.log("See you next time!");
    });
  },
  components: {NoticeBar}
};
</script>

<style lang="scss" scoped="true">
::v-deep {
  .van-nav-bar {
    height: 88px;
    .van-nav-bar__content {
      line-height: 88px;
      height: 88px;
      background-image: $YellowBackgroundImage;
      .van-nav-bar__title {
        font-size: 36px;
        color: $YellowBarColor;
      }
    }
  }
  .van-sticky {
    display: flex;
    color: #6d6d6d;
    font-size: 28px;
    height: 88px;
    line-height: 88px;
    border-bottom: 1px solid #d8d8d8;
    align-items: center;
    background: #ffffff;
    padding: 0 24px;
    li {
      flex: 2;
    }
  }
  .van-sticky--fixed {
    top: 88px;
  }
  .van-notice-bar {
    margin-top: 24px;
    font-size: 28px;
    height: 42px;
    .van-notice-bar__wrap {
      left: 16px;
    }
    .van-notice-bar__left-icon {
      font-size: 32px;
      margin-left: -16px;
    }
  }
}
.home {
  padding-bottom: 120px;
  background-color: #f6f5f9;
  font-family: PingFangSC, PingFang SC, system-ui;
  .domainInfo {
    padding: 32px 24px;
    background-color: #ffffff;
    .main {
      display: flex;
      justify-content: space-between;
      .logo {
        width: 314px;
        height: 104px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .status {
        font-size: 24px;
        color: #6d6d6d;
        .trade {
          float: right;
          margin-top: 10px;
          width: 104px;
          height: 42px;
          text-align: center;
          line-height: 42px;
        }
      }
    }
  }
  .price {
    margin-top: 12px;
    background: #ffffff;
    padding: 0 24px;
    text-align: center;
    .topul {
      display: flex;
      color: #6d6d6d;
      font-size: 28px;
      height: 88px;
      line-height: 88px;
      border-bottom: 1px solid #d8d8d8;
      align-items: center;
    }
    .productlist {
      display: flex;
      height: 134px;
      border-bottom: 1px solid #d8d8d8;
      .productName {
        color: #333333;
        font-weight: 500;
        font-size: 28px;
        height: 40px;
        line-height: 40px;
        margin: 46px 0;
      }
      .productPrice {
        height: 72px;
        line-height: 72px;
        width: 156px;
        font-size: 32px;
        margin: 30px 0;
      }
      .lastli {
        margin: 30px 0;
        font-size: 24px;
        .high {
          color: #333333;
          height: 34px;
          line-height: 34px;
        }
        .low {
          margin-top: 4px;
          height: 34px;
          line-height: 34px;
          color: #a3a3a3;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
    li {
      flex: 2;
    }
  }
  .redfont {
    color: #f94e3c !important;
  }
  .redfontBack {
    color: #ffffff !important;
    background: #f94e3c;
  }
  .greenfont {
    color: #00ad8c !important;
  }
  .greenfontBack {
    color: #ffffff !important;
    background: #32bc77;
  }
  .triangleUp {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 18px solid #ffffff;
    position: relative;
    top: -30px;
    right: 2px;
  }
  .triangleDown {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 18px solid #ffffff;
    position: relative;
    top: 30px;
    right: 2px;
  }
  .beian {
    margin-top: 30px;
    text-align: center;
    font-size: 24px;
    color: #a5a5a5;
  }
  .remark {
    margin: 24px 38px 0;
    color: #a3a3a3;
    font-size: 24px;
    .describe {
      margin-top: 4px;
      word-break: break-all;
      word-wrap: break-word;
    }
  }
}
</style>
